<template>
	<v-card>
		<s-toolbar
			label="Agregar Jabas Dañadas"
			close
			@close="closeDialogDamageJaba()"
			dark
			color="#BAB6B5"
			add
			@add="addDamageJaba()"
		></s-toolbar>
		<v-container>
			<v-row>
				<v-col>
					<v-alert
						type="success"
					>{{infoReception}}</v-alert>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<s-switch
						label="Ingreso manual"
						v-model="manual"
					></s-switch>
				</v-col>
				<v-col v-if="manual">
					<s-date
						label="Fecha Inicio"
						v-model="filter.DateBegin"
					></s-date>
				</v-col>
				<v-col v-if="manual">
					<s-date
						label="Fecha Fin"
						v-model="filter.DateEnd"
					></s-date>
				</v-col>
			</v-row>
			<v-row>
				<v-col v-if="manual">
					<s-select
						label="Lotes Recepcionados"
						:items="lotsReceptionFrozen"
						item-text="RcfName"
						item-value="RcfID"
						@input="changeRcfID($event)"
						returnObject
					></s-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col>

				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<s-select-definition
						label="Tipo de defecto"
						:def="1327"
						v-model="pallet.TypeDamage"
					></s-select-definition>
				</v-col>
				<v-col>
					<s-text 
						label="Cantidad de jabas"
						number
						v-model="pallet.DmgAmountJabas"
					></s-text>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-data-table
						:headers="headersDamageJaba"
						:items="palletsDamage"
						key="DmgID"
					>
						<template v-slot:item.accion="{item}">
							<v-btn
								color="red"
								x-small
								block
								dark
								@click="deleteItemDamage(item)"
							>
								<i class="fas fa-trash"></i>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
	import _sDamageJabaService from "@/services/FrozenProduction/FrzDamageJabaService";

	export default {
		data() {
			return {
				pallet: {},
				palletsDamage: [],
				headersDamageJaba: [
					{text: "ID", value: "DmgID"},
					{text: "Daño", value: "TypeDamageName"},
					{text: "# Jabas", value: "DmgAmountJabas"},
					{text: "Acción", value: "accion"},
				],
				manual: false,
				filter: {
					
				},
				lotsReceptionFrozen: [],
				infoReception: "",
				lotObj: {}
			}
		},

		watch: {
			manual() {
				if(this.manual)
				{
					this.getLotReceptions();
				}
			},

			"filter.DateBegin"()
			{
				if(this.manual)
				{
					this.getLotReceptions();
				}
			},

			"filter.DateEnd"()
			{
				if(this.manual)
				{
					this.getLotReceptions();
				}
			}
		},

		methods: {


			closeDialogDamageJaba() {
				this.$emit("closeDialogDamageJaba")
			},

			addDamageJaba()
			{
				if(this.DmgAmountJabas == "" || this.DmgAmountJabas == 0)
				{
					this.$fun.alert("Ingrese la cantidad de jabas");
					return;
				}
				if(this.lotsReceptionFrozen.length == 0)
				{
					this.$fun.alert("No hay lotes recepcionados");
					return;
				}

				if(!this.manual)
				{
					this.pallet.RcfID = this.lotsReceptionFrozen[0].RcfID;
				}
				else{
					this.pallet.RcfID = this.lotObj.RcfID;
				}
				
				this.pallet.SecStatus = 1;
				this.pallet.UsrCreateID = this.$fun.getUserID();
				this.pallet.UsrUpdateID = this.$fun.getUserID();
				
				_sDamageJabaService
				.save(this.pallet, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.palletsDamage.push(this.pallet);
						this.$fun.alert("Agregado correctamente", "success");
						this.getDamageJaba();
					}
				})
			},

			getLotReceptions()
			{
				_sDamageJabaService
				.listreceptionfrozen(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.lotsReceptionFrozen = resp.data;
						if(this.lotsReceptionFrozen.length > 0)
						{
							this.infoReception = this.lotsReceptionFrozen[0].RcfName;
							this.lotObj = this.lotsReceptionFrozen[0];
							this.getDamageJaba();
						}
					}
				})
			},

			changeRcfID(item)
			{
				if(item != undefined)
				{
					this.infoReception = item.RcfName;
					this.lotObj = item;
					this.getDamageJaba();
				}
			},

			getDamageJaba()
			{
				let obj = {
					RcfID : this.lotObj.RcfID
				}
				console.log("saaaasasas ", obj, this.lotObj);

				_sDamageJabaService
				.list(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.palletsDamage = resp.data;
					}
				})
			},

			deleteItemDamage(item)
			{
				console.log('itemffff   ', item);
				this.$fun.alert("¿Seguro de eliminar?", "question")
				.then(r => {
					if(r.value)
					{
						item.SecStatus = 0;
						item.UsrUpdateID = this.$fun.getUserID();

						_sDamageJabaService
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Eliminado correctamente", "success");
								this.getDamageJaba();
							}
						})
					}
				})
				
			}
		},

		created () {
			
			this.getLotReceptions();
			console.log("saaaasasas ", this.lotObj);
			//this.getDamageJaba();
		},
	}
</script>